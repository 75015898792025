import React from 'react';
import { Jumbotron, Grid } from 'react-bootstrap';

const Banner = () => {
  return (
    <Jumbotron>
      <Grid>
        <h1>Make Soap!</h1>
        <p></p>
      </Grid>
    </Jumbotron>
  );
}

export default Banner;
